import react from "react";
import '../css/main.css'
import AddCart from "./addtocart";
//http://server3.sttv.site/logo1212.png
const Home = ()=>{
    return(
        <>
        ff

        </>
    )
}
export default Home